<!-- front/Footer.vue-->

<template>
  <footer class="footer-container">
    <div class="footer-content">
      <div class="footer-section">
        <div class="logo">
          <!-- https://nuxt.com/modules/icons -->
          <NuxtLink to="/">
            <Icon name="LogoWhite" />
          </NuxtLink>
        </div>
      </div>
      <div class="footer-section">
        <h3>{{ companyName }}</h3>
        <p>{{ companyAddress }}</p>
        <p>{{ companyCity }}, {{ companyCountry }} {{ companyZip }}</p>
        <p>
          <a :href="`tel:${companyTelephone}`">{{ companyTelephone }}</a>
        </p>
        <p>
          <a :href="`mailto:${companyEmail}`">{{ companyEmail }}</a>
        </p>
        <p v -if="companyTaxNumber">
          {{ $t('taxNumber') }}: {{ companyTaxNumber }}
        </p>
        <p v-if="companyIban">{{ $t('iban') }}: {{ companyIban }}</p>
      </div>
      <div class="footer-section">
        <h3>{{ $t('footerUsefullLinksTitle') }}</h3>
        <ul>
          <li v-for="page in footerPages" :key="page._id">
            <NuxtLink :to="`/page/${page.slug}?lang=${currentLocale}`">{{
              page.title
            }}</NuxtLink>
          </li>
        </ul>
      </div>
      <div class="footer-section">
        <h3>{{ $t('footerFollowUs') }}</h3>
        <div class="social-icons">
          <a v-if="facebookUrl != 'false'" :href="facebookUrl" target="_blank">
            <Icon name="dashicons:facebook" />
          </a>
          <a v-if="twitterUrl != 'false'" :href="twitterUrl" target="_blank">
            <Icon name="simple-icons:x" />
          </a>
          <a
            v-if="instagramUrl != 'false'"
            :href="instagramUrl"
            target="_blank"
          >
            <Icon name="dashicons:instagram" />
          </a>

          <a v-if="youtubeUrl != 'false'" :href="youtubeUrl" target="_blank">
            <Icon name="dashicons:youtube" />
          </a>

          <a v-if="tiktokUrl != 'false'" :href="tiktokUrl" target="_blank">
            <Icon name="simple-icons:tiktok" />
          </a>
        </div>
      </div>
      <div class="footer-section">
        <h3>{{ $t('supportAgent') }}</h3>
        <a :href="`tel:${companyTelephone}`" class="support-agent-icon"
          ><Icon name="dashicons:format-status" size="4rem"
        /></a>
      </div>
    </div>
    <hr class="gradient-hr" />
    <div class="footer-bottom">
      <p>
        &copy; {{ currentYear }} {{ companyName }}
        {{ $t('footerCopyrightTitle') }}
      </p>
    </div>
  </footer>
</template>

<script setup lang="ts">
defineProps<{
  footerPages: any[]
}>()

const { isMobile, isMobileInitialized } = useIsMobile()
const { t: $t, locale } = useI18n()
const currentLocale = computed(() => locale.value)
const route = useRoute()
const runtimeConfig = useRuntimeConfig()
const companyName = ref(runtimeConfig.public.companyName)
const companyTelephone = ref(runtimeConfig.public.companyTelephone)
const companyEmail = ref(runtimeConfig.public.companyEmail)
const companyAddress = ref(runtimeConfig.public.companyAddress)
const companyCity = ref(runtimeConfig.public.companyCity)
const companyCountry = ref(runtimeConfig.public.companyCountry)
const companyZip = ref(runtimeConfig.public.companyZip)
const companyTaxNumber = ref(runtimeConfig.public.companyTaxNumber)
const companyIban = ref(runtimeConfig.public.companyIban)

const currentYear = ref(new Date().getFullYear())
const facebookUrl = ref(runtimeConfig.public.facebookUrl)
const twitterUrl = ref(runtimeConfig.public.twitterUrl)
const instagramUrl = ref(runtimeConfig.public.instagramUrl)
const youtubeUrl = ref(runtimeConfig.public.youtubeUrl)
const tiktokUrl = ref(runtimeConfig.public.tiktokUrl)

// Watch for changes in the route to update the locale
watch(route, (newRoute) => {
  const localeParam = newRoute.query.lang
  if (localeParam && localeParam !== locale.value) {
    locale.value = localeParam as string
  }
})

// Set the locale based on the URL parameter on initial load
if (route.query.lang && route.query.lang !== locale.value) {
  locale.value = route.query.lang as string
}
</script>

<style scoped>
.footer-container {
  width: 100%;
  color: #fff;
  padding: 6rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-container div {
  width: 100%;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}

.footer-content p,
.footer-content a {
  color: var(--footer-text-color);
  text-decoration: none;
}

.footer-content a:hover {
  color: var(--footer-text-color-hover);
}

.footer-bottom p,
.footer-bottom a {
  color: var(--footer-text-color);
  text-decoration: none;
}

.footer-bottom a:hover {
  color: var(--footer-text-color-hover);
}

.footer-section {
  flex: 1;
  margin: 0 1rem;
}

.footer-section h3 {
  margin-bottom: 0.5rem;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 0.5rem;
}

.social-icons a {
  margin-right: 0.5rem;
  color: #fff;
  font-size: 1.5rem;
}

.footer-bottom {
  text-align: center;
  border-top: var(--secondary-color);
  padding-top: 1rem;
  width: 100%;
}

.gradient-hr {
  border: 0;
  height: 1px;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(219, 219, 219, 1) 100%
  );
  width: 100%;
  margin: 1rem 0;
  opacity: 0.1;
}

.logo svg {
  width: 160px;
  height: 40px;
}

.support-agent-icon .iconify {
  font-size: 2.5rem !important;
}

/* Mobile Styles */
@media (max-width: 1025px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    margin-bottom: 1rem;
    text-align: center;
  }

  .footer-section ul {
    padding: 0;
  }

  .footer-section ul li {
    margin-bottom: 0.5rem;
  }

  .social-icons a {
    margin: 0 0.5rem;
  }
}
</style>
