<!-- front/SearchHeader.client.vue-->

<template>
  <div class="events-search">
    <div class="search-input-container">
      <input
        type="text"
        v-model="searchQuery"
        @input="debouncedSearch"
        :placeholder="$t('searchAnything')"
        class="search-input"
      />
      <span v-if="searchQuery" class="clear-icon" @click="clearSearch">✖</span>
    </div>

    <div v-if="events.length > 0" class="search-results">
      <div class="event-item flex" v-for="event in events" :key="event.id">
        <a :href="`/event/${event.slug}`">
          <img :src="event.media.files.thumb50" alt="" />
        </a>
        <a :href="`/event/${event.slug}`">
          <h4>{{ event.title }}</h4>
          <p>{{ dateFormatter(event.date, locale) }}</p>
        </a>
      </div>
    </div>

    <div v-if="searchQuery.trim() !== '' && events.length == 0 && !apiReq">
      <div class="search-results padding-1">
        {{ $t('nothingFoundforThisSearchCriteria') }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { dateFormatter } from '@/composables/dateFormatter'
import debounce from 'lodash/debounce'
const { isMobile, isMobileInitialized } = useIsMobile()

const { t: $t, locale } = useI18n()
const emit = defineEmits(['searching'])

const searchQuery = ref('')
const events = ref([])
const error = ref('')
const apiReq = ref(false)

// Method to handle keydown events
const handleKeydown = (event) => {
  if (event.key === 'Escape') {
    clearSearch()
  }
}

const handleSearch = async () => {
  apiReq.value = true
  emit('searching', true)

  if (searchQuery.value.trim() === '') {
    emit('searching', false)
    events.value = []
    return
  }

  error.value = ''
  events.value = []

  const eventStore = useEventStoreFront()

  const data = await eventStore.search({ search: searchQuery.value })
  events.value = data.events

  apiReq.value = false
}

// Debounce the search function to delay the execution until the user stops typing
const debouncedSearch = debounce(handleSearch, 1000)

const clearSearch = () => {
  searchQuery.value = ''
  events.value = []
  error.value = ''
  emit('searching', false)
}

// Add event listener for keydown events
onMounted(() => {
  window.addEventListener('keydown', handleKeydown)
})

// Remove event listener when component is unmounted
onUnmounted(() => {
  window.removeEventListener('keydown', handleKeydown)
})
</script>

<style scoped>
.events-search {
  margin: 1rem 0;
  position: relative;
  z-index: 101;
}

.search-input-container {
  position: relative;
}

.search-input {
  padding: 0.5rem;
  font-size: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  padding-right: 2rem;
  background-color: #fff;
}

.clear-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.2rem;
  color: #ccc;
  transition: color 0.3s;
}

.clear-icon:hover {
  color: #000;
}

.search-input {
  padding: 0.5rem;
  font-size: 1rem;
  width: 100%;
  margin-bottom: 1rem;
}

.loading,
.error,
.no-results {
  margin-top: 1rem;
  font-size: 1rem;
  color: #333;
}

.search-results {
  margin-top: 1rem;
  background-color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 32px;
  border: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 100;
  text-align: left;
}

.search-results .event-item {
  align-items: center;
  flex-wrap: unset;
}

.event-item {
  padding: 1rem;
}

.event-item h4 {
  margin: 0 0 0.5rem 0;
}

.event-item p {
  margin: 0.25rem 0;
}

.event-item img {
  width: 50px;
  height: auto;
  margin-right: 1rem;
}
</style>
