<!-- front/Header.vue-->

<template>
  <div class="header-container flex gap-1">
    <div class="logo">
      <!-- https://nuxt.com/modules/icons -->
      <NuxtLink :to="homeLink">
        <Icon name="LogoColor" />
      </NuxtLink>
    </div>

    <div class="header-search-container">
      <FrontSearchHeader @searching="handleSearching" />
    </div>

    <div class="primary-menu flex gap-1">
      <ul class="flex gap-1">
        <li class="homepage-link">
          <NuxtLink :to="homeLink">{{ homeLinkTitle }}</NuxtLink>
        </li>

        <li class="allevents-link">
          <NuxtLink :to="archiveLink">{{ archiveLinkTitle }}</NuxtLink>
        </li>

        <li v-for="page in primaryPages" :key="page.id">
          <NuxtLink :to="`/page/${page.slug}?lang=${page.language}`">{{
            page.title
          }}</NuxtLink>
        </li>

        <li class="header-cart-icon">
          <NuxtLink to="/login">
            <Icon name="dashicons:admin-users"></Icon>
          </NuxtLink>
        </li>

        <li class="header-cart-icon">
          <NuxtLink to="/checkout">
            <Icon name="dashicons:cart"></Icon>
          </NuxtLink>
        </li>
        <li>
          <div
            v-if="showLanguageSwitcher"
            class="language-switcher"
            ref="dropdownRef"
          >
            <div class="selected-language" @click="toggleDropdown">
              <img
                :src="currentLocale?.flag"
                :alt="currentLocale?.name"
                class="flag-icon"
              />
              <span class="lang-code">{{
                currentLocale?.code.toUpperCase()
              }}</span>
              <span class="arrow" :class="{ open: dropdownOpen }">
                <Icon name="dashicons:arrow-down-alt2" />
              </span>
            </div>
            <ul v-if="dropdownOpen" class="dropdown">
              <li
                v-for="locale in filteredLocales"
                :key="locale.code"
                @click="changeLanguage(locale.code)"
                class="dropdown-item"
              >
                <img :src="locale.flag" :alt="locale.name" class="flag-icon" />
                <span class="lang-code">{{ locale?.code.toUpperCase() }}</span>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div v-if="isSearching" class="overlay"></div>
</template>

<script lang="ts" setup>
import { useSettingsData } from '~/store/settings'
import { useClickOutside } from '@/composables/clickOutside'

defineProps<{
  primaryPages: any[]
}>()

const { locale, availableLocales } = useI18n()
const { isMobile, isMobileInitialized } = useIsMobile()

const router = useRouter()

// const currentLocale = computed(() => locale.value)
const route = useRoute()
const runtimeConfig = useRuntimeConfig()

const selectedLanguage = ref(locale.value)
const showLanguageSwitcher = ref(false)
const dropdownOpen = ref(false)
const dropdownRef = ref(null)
const siteAvailableLocales = runtimeConfig.public.siteAvailableLanguages
const isSearching = ref(false)

// We are getting all design settings from the store
const handleSearching = (searching: any) => {
  isSearching.value = searching
}

const settingsStore = useSettingsData()

const getDesign = computed(() => settingsStore.design)

onMounted(() => {
  showLanguageSwitcher.value = getDesign.value.data.showLanguageSwitcher
})

const locales = [
  { code: 'en', name: 'English', flag: '/flags/4x3/gb-eng.svg' },
  { code: 'sr', name: 'Serbian', flag: '/flags/4x3/rs.svg' },
  { code: 'de', name: 'German', flag: '/flags/4x3/de.svg' },
  { code: 'es', name: 'Spanish', flag: '/flags/4x3/es.svg' },
  { code: 'nl', name: 'Dutch', flag: '/flags/4x3/nl.svg' },
  { code: 'ru', name: 'Russian', flag: '/flags/4x3/ru.svg' },
  { code: 'se', name: 'Sweden', flag: '/flags/4x3/se.svg' },
]

const filteredLocales = computed(() => {
  return locales.filter((locale) => {
    return siteAvailableLocales.includes(locale.code)
  })
})

// Watch for changes in the locale to update the selected language
watch(locale, (newLocale) => {
  selectedLanguage.value = newLocale
})

// Watch for changes in the route to update the locale
watch(route, (newRoute) => {
  const localeParam = newRoute.query.lang
  if (localeParam && localeParam !== locale.value) {
    locale.value = localeParam as string
  }
})

// Set the locale based on the URL parameter on initial load
if (route.query.lang && route.query.lang !== locale.value) {
  locale.value = route.query.lang as string
}

// Create a computed property for `homeLink`
/*
const homeLink = computed(() => {
  return { path: '/', query: { lang: locale.value } }
})
*/

const homeLink = computed(() => {
  return `/?lang=${locale.value}`
})

const homeLinkTitle = computed(() => {
  switch (locale.value) {
    case 'en':
      return 'Home'
    case 'sr':
      return 'Početna'
    case 'de':
      return 'Startseite'
    case 'es':
      return 'Inicio'
    case 'fr':
      return 'Accueil'
    case 'it':
      return 'Home'
    case 'jp':
      return 'ホーム'
    case 'cn':
      return '首页'
    case 'ru':
      return 'Главная'
    case 'se':
      return 'Hem'
    case 'nl':
      return 'Home'
    default:
      return 'Home'
  }
})

// Define the computed property for `archiveLink`
const archiveLink = computed(() => {
  return locale.value === 'en'
    ? { path: '/archive' }
    : { path: '/archive', query: { lang: locale.value } }
})

const archiveLinkTitle = computed(() => {
  switch (locale.value) {
    case 'en':
      return 'All Events'
    case 'sr':
      return 'Svi događaji'
    case 'de':
      return 'Alle Veranstaltungen'
    case 'es':
      return 'Todos los eventos'
    case 'fr':
      return 'Tous les événements'
    case 'it':
      return 'Tutti gli eventi'
    case 'jp':
      return 'すべてのイベント'
    case 'cn':
      return '所有活动'
    case 'ru':
      return 'Все события'
    case 'se':
      return 'Alla evenemang'
    case 'nl':
      return 'Alle evenementen'
    default:
      return 'All Events'
  }
})

const currentLocale = computed(() => {
  return locales.find((loc) => loc.code === locale.value)
})

const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value
}

const changeLanguage = (code: string) => {
  locale.value = code
  dropdownOpen.value = false

  selectedLanguage.value = code
  router.push({ query: { ...route.query, lang: code } })
}

useClickOutside(dropdownRef, () => {
  dropdownOpen.value = false
})
</script>

<style scoped>
.header-container {
  background-color: transparent;
  text-align: right;
}

.header-container.flex {
  justify-content: space-between;
}

.logo svg {
  width: 160px;
  height: 40px;
}

.header-cart-icon a {
  font-size: 18px;
}

.primary-menu .flex {
  justify-content: space-between;
}

.primary-menu ul {
  display: flex;
  list-style: none;
  padding: 0;
}

.primary-menu ul li {
  margin-left: 1em;
}

.primary-menu ul li a {
  color: var(--header-text-color);
  text-decoration: none;
}

.primary-menu ul li a:hover {
  color: var(--header-text-color-hover);
}

@media (max-width: 1025px) {
  .header-container {
    margin: 0 auto;
    z-index: 10;
    position: sticky;
    top: 0;
    right: 0;
  }
}

.language-switcher {
  position: relative;
  display: inline-block;
  max-width: 90px;
}

.language-switcher ul {
  display: block;
}
.language-switcher ul li {
  margin: 0;
}

.lang-code {
  margin-right: 0.5rem;
  font-size: 1rem;
  width: 50px;
  text-align: center;
}
.selected-language {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, box-shadow 0.3s;
}

.selected-language:hover {
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.flag-icon {
  width: auto;
  height: 12px;
  object-fit: contain;
  margin-right: 0.5rem;
}

.arrow {
  transition: transform 0.3s;
}

.arrow.open {
  transform: rotate(180deg);
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.dropdown-item {
  padding: 0.75rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
}

.dropdown-item:hover {
  background: #f9f9f9;
}

.dropdown-item img {
  width: 24px;
  height: 16px;
  margin-right: 0.5rem;
}

.header-search-container {
  width: 400px;
}
</style>
