declare global {
    interface Window {
        dataLayer: any[];
        gtag?: (...args: any[]) => void;
        [key: `ga-disable-${string}`]: boolean;
    }
}

export function useGa4Store() {

    const init = (id: string): void => {
        if (!window || !id) return;

        if (id !== undefined) {

            // Load the Google Analytics gtag script
            const script = document.createElement('script');
            script.id = 'ga4-script';
            script.async = true;
            script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
            document.head.appendChild(script);

            // Initialize gtag
            window.dataLayer = window.dataLayer || [];
            window.gtag = function gtag() {
                // eslint-disable-next-line prefer-rest-params
                window.dataLayer.push(arguments);
            };
            window.gtag('js', new Date());
            window.gtag('config', id, { anonymize_ip: true });
        }

    }

    const stop = (id: string): void => {

        if (!window.gtag) return;

        // Manually delete ga cookies
        // Set the expiration date in the past
        const pastDate = new Date(0).toUTCString();
        // Get all cookies
        const cookies: string[] = document.cookie.split(";");
        // Iterate over each cookie
        for (let i = 0; i < cookies.length; i++) {
            const cookie: string = cookies[i];
            // Check if the cookie is related to Google Analytics
            if (cookie.indexOf("_ga") === 0 || cookie.indexOf("_gat") === 0 || cookie.indexOf("_gid") === 0) {
                const cookieName: string = cookie.split("=")[0];
                // Delete the cookie by setting its expiration to the past
                document.cookie = `${cookieName}=; expires=${pastDate}; path=/`;
            }
        }

        // Remove created tracking script
        const ga4_script = document.getElementById('ga4-script');
        if (ga4_script !== null) {
            ga4_script.remove();
        }

    }

    return {
        init,
        stop
    };

}
