declare global {
  interface Window {
    fbq: (...args: any[]) => void;
  _fbq?: (...args: any[]) => void;
}
}

export function useFbPixelStore() {

    const init = (id: string): void => {

        if (!window || !id) return;

        (function (f, b, e, v, n, t, s) {
          if (typeof f.fbq === 'function') return;
          (n as any) = f.fbq = function () {
            (n as any).callMethod
              ? (n as any).callMethod.apply(n, arguments)
              : (n as any).queue.push(arguments);
          };
    
          if (!f._fbq) f._fbq = n;
    
          (n as any).push = n;
    
          (n as any).loaded = !0;
    
          (n as any).version = '2.0';
    
          (n as any).queue = [];
    
          (t as any) = b.createElement(e);
    
          (t as any).async = !0;
    
          (t as any).id = 'facebook-pixel-script';
    
          (t as any).src = v;
    
          (s as any) = b.getElementsByTagName(e)[0];
    
          (s as any).parentNode.insertBefore(t, s);
        })(
          window,
          document,
          'script',
          'https://connect.facebook.net/en_US/fbevents.js',
        );
    
        window.fbq('init', id);

    }

    const stop = (id: string): void => {

        if (!window.fbq) return;

        if (id !== undefined) {
            // Remove created tracking script
            const fb_script = document.getElementById('facebook-pixel-script');
            if(fb_script !== null){
              fb_script.remove();
            }
        }

    }

    return {
        init,
        stop
    };

}
