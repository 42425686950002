/* OLD WAY 
declare global {
    interface Window {
        dataLayer: any[];
        gtag?: (...args: any[]) => void;
        [key: `ga-disable-${string}`]: boolean;
    }
}

export function useGtmStore() {

    const init = (id: string): void => {
        if (!window || !id) return;

        if (id !== undefined) {
            // Create a new script element
            const script = document.createElement('script');
            script.id = 'gtm-script';
            script.async = true;
            script.src = `https://www.googletagmanager.com/gtm.js?id=${id}`;

            // Append the script to the <head> element
            document.head.appendChild(script);

            // Add the GTM noscript tag to the <body> section of the page
            const noscript = document.createElement('noscript');
            noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?${id}"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

            // Inject the noscript tag into the <body> section of the page
            document.body.appendChild(noscript);

            // Ensure the dataLayer array exists
            window.dataLayer = window.dataLayer || [];

            // Push the consent configuration to the dataLayer
            window.dataLayer.push(
                'consent', 'default', {
                'ad_storage': 'denied',
                'ad_user_data': 'denied',
                'ad_personalization': 'denied',
                'analytics_storage': 'denied'
            }
            );
        }
    }

    const grantConsent = (): void => {
        // Ensure the dataLayer array exists
        window.dataLayer = window.dataLayer || [];

        // Push the updated consent configuration to the dataLayer to grant all consents
        window.dataLayer.push(
            'consent', 'update', {
            'ad_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'analytics_storage': 'granted'
        }
        );
    }

    const stop = (id: string): void => {

        if (!window.gtag || !id) return;

        if (id !== undefined) {
            // Remove created tracking script
            const gtm_script = document.getElementById('gtm-script');
            if (gtm_script !== null) {
                gtm_script.remove();
            }
            // Stop GA4 tracking and remove cookies
            window.gtag('config', id, {
                send_page_view: false, // Stop sending page views
                cookie_flags: 'max-age=0;secure;samesite=none', // Remove cookies
            });
            // Disable tracking id to send tracking data
            window[`ga-disable-${id}`] = true;
            window.gtag('set', 'allow_google_signals', false);
        }
    }

    return {
        init,
        grantConsent,
        stop
    };

}

*/

// types/gtm.d.ts

declare global {
  interface Window {
    dataLayer: any[]
    gtag?: (...args: any[]) => void
    [key: `ga-disable-${string}`]: boolean
  }
}
export type GTMEvent = {
  event: string
  [key: string]: any
}

export type ConsentOptions = {
  ad_storage?: 'granted' | 'denied'
  analytics_storage?: 'granted' | 'denied'
  functionality_storage?: 'granted' | 'denied'
  personalization_storage?: 'granted' | 'denied'
  security_storage?: 'granted' | 'denied'
}

export function useGtmStore() {
  const init = (id: string): void => {
    if (!window || !id) return

    if (id !== undefined) {
      // Initialize `dataLayer` with TypeScript support
      window.dataLayer = window.dataLayer || []

      // Define the `gtag` function with types
      window.gtag = function gtag(...args: any[]) {
        window.dataLayer.push(arguments)
      }

      // Initialize GTM with default consent settings
      const defaultConsent: ConsentOptions = {
        ad_storage: 'denied',
        analytics_storage: 'denied',
      }

      window.gtag('consent', 'default', defaultConsent)

      // Fully typed GTM initialization function
      ;(function (w: Window, d: Document, s: string, l: string, i: string) {
        const dataLayer = w[l as keyof Window] || []
        dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
        const f = d.getElementsByTagName(s)[0] as HTMLScriptElement,
          j = d.createElement(s) as HTMLScriptElement,
          dl = l !== 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
        f.parentNode!.insertBefore(j, f)
      })(window, document, 'script', 'dataLayer', id)

      // Provide `$gtag` function with types
      const nuxtApp = useNuxtApp()
      nuxtApp.provide('gtag', window.gtag)
    }
  }

  const grantConsent = (): void => {
    // Ensure the dataLayer array exists
    window.dataLayer = window.dataLayer || []

    // Push the updated consent configuration to the dataLayer to grant all consents
    window.dataLayer.push('consent', 'update', {
      ad_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      analytics_storage: 'granted',
    })
  }

  const stop = (id: string): void => {
    if (!window.gtag || !id) return

    if (id !== undefined) {
      // Remove created tracking script
      const gtm_script = document.getElementById('gtm-script')
      if (gtm_script !== null) {
        gtm_script.remove()
      }
      // Stop GA4 tracking and remove cookies
      window.gtag('config', id, {
        send_page_view: false, // Stop sending page views
        cookie_flags: 'max-age=0;secure;samesite=none', // Remove cookies
      })
      // Disable tracking id to send tracking data
      window[`ga-disable-${id}`] = true
      window.gtag('set', 'allow_google_signals', false)
    }
  }

  return {
    init,
    grantConsent,
    stop,
  }
}

/*
   Custom event push
  
   export type GTMEvent = {
    event: string; // The event name
    [key: string]: any; // Additional properties
  };
  
  export type CustomEvent = {
    event: string;
    event_category?: string;
    event_action?: string;
    event_label?: string;
    value?: number;
  };
  
  
   <template>
    <button @click="trackButtonClick">Click Me</button>
  </template>
  
  <script setup lang="ts">
  import { CustomEvent } from '~/types/gtm';
  const { $gtag } = useNuxtApp();
  
  const trackButtonClick = () => {
    const event: CustomEvent = {
      event: 'button_click',
      event_category: 'Button',
      event_action: 'click',
      event_label: 'Click Me Button',
      value: 1,
    };
  
    $gtag('event', event.event, event);
  };
  </script>
  
  
  */
