<template>
  <div :class="{ loading: !isLoaded }">
    <FrontCookieBanner />
    <div class="container-fluid primary-menu-container">
      <div v-if="!isMobile" class="container">
        <FrontHeader :primaryPages="primaryPages" />
      </div>

      <div v-if="isMobile" class="container">
        <FrontHeaderMobile :primaryPages="primaryPages" />
      </div>
    </div>
    <div class="container-fluid">
      <!-- https://nuxt.com/docs/guide/directory-structure/layouts#default-layout -->
      <slot />
    </div>
    <div class="container-fluid primary-footer-container">
      <div class="container">
        <FrontFooter :footerPages="footerPages" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { isMobile, isMobileInitialized } = useIsMobile()

const pageStoreFront = usePageStoreFront()

const { locale } = useI18n()
const currentLocale = ref(locale.value)

const primaryPages = ref<any[]>([])
const footerPages = ref<any[]>([])
const isLoaded = ref(false)

// Set primary and footer pages
const getPages = async () => {
  const pagesData = await pageStoreFront.getAll(currentLocale.value.toString())
  if (pagesData.pages.length > 0) {
    footerPages.value = pagesData.pages.filter((page: any) => page.isFooterPage)
    primaryPages.value = pagesData.pages.filter(
      (page: any) => page.isPrimaryMenuPage
    )
  } else {
    footerPages.value = []
    primaryPages.value = []
  }
}

watch(locale, (newLocale) => {
  currentLocale.value = newLocale
  getPages()
})

onMounted(() => {
  getPages()
  // You can check for CSS loading or simply delay rendering
  setTimeout(() => {
    isLoaded.value = true
  }, 500) // Use appropriate time based on your setup
})
</script>

<style scoped>
/* Use a class to hide the content initially */
.loading {
  visibility: hidden;
}
</style>
