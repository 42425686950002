export function useAdWordsStore() {
  const init = (id: string): void => {
    if (!window || !id) return

    if (id !== undefined) {
      // Add Google Ads Global Site Tag (gtag.js) to the page
      const script = document.createElement('script')
      script.async = true
      script.id = 'adwords-script'
      script.src = `https://www.googletagmanager.com/gtag/js?id=${id}` // Replace with your Google Ads Conversion ID (AW-XXXXXXXXX)
      document.head.appendChild(script)

      script.onload = () => {
        // Initialize gtag after script is loaded
        window.dataLayer = window.dataLayer || []
        function gtag(...args: any[]) {
          window.dataLayer.push(args)
        }
        gtag('js', new Date())
        gtag('config', id) // Replace with your Google Ads Conversion ID
      }
    }
  }

  const stop = (id: string): void => {
    if (!window.gtag || !id) return

    if (id !== undefined) {
      // Remove created tracking script
      const adwords_script = document.getElementById('adwords-script')
      if (adwords_script !== null) {
        adwords_script.remove()
      }
    }
  }

  return {
    init,
    stop,
  }
}
