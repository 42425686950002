<!-- front/HeaderMobile.vue-->

<template>
  <div class="header-container flex">
    <div class="logo">
      <!-- https://nuxt.com/modules/icons -->
      <NuxtLink :to="homeLink">
        <Icon name="LogoColor" />
      </NuxtLink>
    </div>

    <div class="header-search-container">
      <FrontSearchHeader @searching="handleSearching" />
    </div>

    <button
      @click="openPanel"
      :class="['hamburger-menu', { open: isPanelOpen }]"
    >
      <span></span>
      <span></span>
      <span></span>
    </button>

    <div
      :class="['slide-panel', { open: isPanelOpen }, { closed: isPanelClosed }]"
      @click="handleClick"
    >
      <div class="slide-panel-container">
        <div class="menu-items">
          <!-- Main items -->
          <ul>
            <li @click="handleClick">
              <NuxtLink to="/">{{ homeLinkTitle }}</NuxtLink>
            </li>

            <li class="allevents-link">
              <NuxtLink :to="archiveLink">{{ archiveLinkTitle }}</NuxtLink>
            </li>

            <li
              @click="handleClick"
              v-for="page in primaryPages"
              :key="page.id"
            >
              <NuxtLink :to="`/page/${page.slug}`">{{ page.title }}</NuxtLink>
            </li>

            <li @click="handleClick">
              <NuxtLink to="/login">
                <Icon name="dashicons:admin-users"></Icon>
                <span>Login</span>
              </NuxtLink>
            </li>

            <li @click="handleClick">
              <NuxtLink to="/checkout">
                <Icon name="dashicons:cart"></Icon>
                <span>Checkout</span>
              </NuxtLink>
            </li>
          </ul>

          <div v-if="showLanguageSwitcher">
            <ul>
              <li
                v-for="locale in filteredLocales"
                :key="locale.code"
                @click="changeLanguage(locale.code)"
                class="lang-item"
              >
                <img :src="locale.flag" :alt="locale.name" class="flag-icon" />
                <span class="lang-code">{{ locale?.code.toUpperCase() }}</span>
              </li>
            </ul>
          </div>

          <br />
          <!-- Logout 
          <ul>
            <li>
              <p @click="logoutAction">
                <Icon name="dashicons:admin-users"></Icon>
                <span>Logout</span>
              </p>
            </li>
          </ul>
          -->
        </div>
      </div>
    </div>
    <!-- Overlay -->
    <div v-if="isSearching" class="overlay"></div>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  primaryPages: any[]
}>()

import { useAuthStore } from '~/store/auth' // import the auth store we just created
import { useSettingsData } from '~/store/settings'

import { useClickOutside } from '@/composables/clickOutside'
const { locale, availableLocales } = useI18n()

const { logOutUser } = useAuthStore() // use loginUser action from  auth store

const router = useRouter()
const route = useRoute()
const runtimeConfig = useRuntimeConfig()

const isPanelOpen = ref(false)
const isPanelClosed = ref(false)
const slidePanel = ref(null)
const isSearching = ref(false)

const selectedLanguage = ref(locale.value)
const showLanguageSwitcher = ref(false)
const dropdownOpen = ref(false)
const dropdownRef = ref(null)
const siteAvailableLocales = runtimeConfig.public.siteAvailableLanguages

// We are getting all design settings from the store
const handleSearching = (searching: any) => {
  isSearching.value = searching
}

const settingsStore = useSettingsData()

const getDesign = computed(() => settingsStore.design)

const locales = [
  { code: 'en', name: 'English', flag: '/flags/4x3/gb-eng.svg' },
  { code: 'sr', name: 'Serbian', flag: '/flags/4x3/rs.svg' },
  { code: 'de', name: 'German', flag: '/flags/4x3/de.svg' },
  { code: 'es', name: 'Spanish', flag: '/flags/4x3/es.svg' },
  { code: 'nl', name: 'Dutch', flag: '/flags/4x3/nl.svg' },
  { code: 'ru', name: 'Russian', flag: '/flags/4x3/ru.svg' },
  { code: 'se', name: 'Sweden', flag: '/flags/4x3/se.svg' },
]

const filteredLocales = computed(() => {
  return locales.filter((locale) => {
    return siteAvailableLocales.includes(locale.code)
  })
})

// Watch for changes in the locale to update the selected language
watch(locale, (newLocale) => {
  selectedLanguage.value = newLocale
})

// Watch for changes in the route to update the locale
watch(route, (newRoute) => {
  const localeParam = newRoute.query.lang
  if (localeParam && localeParam !== locale.value) {
    locale.value = localeParam as string
  }
})

// Set the locale based on the URL parameter on initial load
if (route.query.lang && route.query.lang !== locale.value) {
  locale.value = route.query.lang as string
}

// Create a computed property for `homeLink`
/*
const homeLink = computed(() => {
  return { path: '/', query: { lang: locale.value } }
})
*/

const homeLink = computed(() => {
  return `/?lang=${locale.value}`
})
const homeLinkTitle = computed(() => {
  switch (locale.value) {
    case 'en':
      return 'Home'
    case 'sr':
      return 'Početna'
    case 'de':
      return 'Startseite'
    case 'es':
      return 'Inicio'
    case 'fr':
      return 'Accueil'
    case 'it':
      return 'Home'
    case 'jp':
      return 'ホーム'
    case 'cn':
      return '首页'
    case 'ru':
      return 'Главная'
    case 'se':
      return 'Hem'
    case 'nl':
      return 'Home'
    default:
      return 'Home'
  }
})

// Define the computed property for `archiveLink`
const archiveLink = computed(() => {
  return locale.value === 'en'
    ? { path: '/archive' }
    : { path: '/archive', query: { lang: locale.value } }
})

const archiveLinkTitle = computed(() => {
  switch (locale.value) {
    case 'en':
      return 'All Events'
    case 'sr':
      return 'Svi događaji'
    case 'de':
      return 'Alle Veranstaltungen'
    case 'es':
      return 'Todos los eventos'
    case 'fr':
      return 'Tous les événements'
    case 'it':
      return 'Tutti gli eventi'
    case 'jp':
      return 'すべてのイベント'
    case 'cn':
      return '所有活动'
    case 'ru':
      return 'Все события'
    case 'se':
      return 'Alla evenemang'
    case 'nl':
      return 'Alle evenementen'
    default:
      return 'All Events'
  }
})

const currentLocale = computed(() => {
  return locales.find((loc) => loc.code === locale.value)
})

const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value
}

const changeLanguage = (code: string) => {
  locale.value = code
  dropdownOpen.value = false

  selectedLanguage.value = code
  router.push({ query: { ...route.query, lang: code } })
}

onMounted(() => {
  showLanguageSwitcher.value = getDesign.value.data.showLanguageSwitcher
})

const openPanel = () => {
  isPanelOpen.value = !isPanelOpen.value
  isPanelClosed.value = false

  const contentElement = document.querySelector('.content') as HTMLElement
  if (contentElement) {
    if (isPanelOpen.value) {
      contentElement.style.backgroundColor = 'rgba(0, 0, 0, 0.2)'
      contentElement.style.opacity = '0.2'
    } else {
      contentElement.style.backgroundColor = ''
      contentElement.style.opacity = '1'
    }
  }
}

const closePanel = () => {
  const contentElement = document.querySelector('.content') as HTMLElement
  if (contentElement) {
    contentElement.style.backgroundColor = ''
    contentElement.style.opacity = '1'
  }
  isPanelOpen.value = false
  isPanelClosed.value = true
}

const handleClick = () => {
  closePanel()
}

const logoutAction = () => {
  logOutUser()
  router.push('/login')
}
</script>

<style scoped>
.header-container {
  background-color: #ffffff;
  text-align: left;
  margin-left: 20%;
  z-index: 10;
  position: sticky;
  top: 0;
  right: 0;
}

.header-container.flex {
  justify-content: space-between;
}

.logo svg {
  height: 35px;
  width: 120px;
}

@media (max-width: 1025px) {
  .header-container {
    margin: 0 auto;
    z-index: 10;
    position: sticky;
    top: 0;
    right: 0;
  }
}

/* Mobile slide panel */

.slide-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 85%;
  height: 100%;
  background-color: #111827;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
  visibility: hidden;
  z-index: 103;
}

.slide-panel.open {
  transform: translateX(0);
  visibility: visible;
}

.slide-panel.closed {
  transform: translateX(100%);
  visibility: hidden;
}

.slide-panel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: 100%;
  padding: 2em;
  z-index: 1000;
}

.slide-panel-trigger-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.menu-items a {
  display: flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
  font-size: 1rem;
}

.menu-items p {
  color: #ffffff;
}

.menu-items a span,
.menu-items p span {
  margin-left: 1em;
}

.menu-items li {
  margin-bottom: 1.2em;
}

.slide-panel .menu-items {
  opacity: 0;
  transition: opacity 1s ease-in-out, opacity 1s ease-in-out;
}

.slide-panel.open .menu-items {
  opacity: 1;
}

/* Hamburger menu styles */

.hamburger-menu {
  position: fixed;
  top: 30px;
  right: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 40px;
  height: 25px;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 1000;
}

.hamburger-menu span {
  width: 100%;
  height: 2px;
  background: #111827;
  border-radius: 2px;
  transition: all 0.3s ease-in-out;
}

.hamburger-menu.open span {
  background: #fff;
}

.hamburger-menu.open span:nth-child(1) {
  transform: rotate(45deg) translate(8px, 5px);
}

.hamburger-menu.open span:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.open span:nth-child(3) {
  transform: rotate(-45deg) translate(6px, -3px);
}

.language-switcher {
  position: relative;
  display: inline-block;
  max-width: 90px;
}

.language-switcher ul {
  display: block;
}
.language-switcher ul li {
  margin: 0;
}

.lang-code {
  margin-right: 0.5rem;
  font-size: 1rem;
  width: 50px;
  text-align: center;
  color: #fff;
}
.selected-language {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, box-shadow 0.3s;
}

.selected-language:hover {
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.flag-icon {
  width: auto;
  height: 12px;
  object-fit: contain;
  margin-right: 0.5rem;
}

.lang-item {
  padding: 0.75rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
}

.lang-item:hover {
  background: #f9f9f9;
}

.lang-item img {
  width: 24px;
  height: 16px;
  margin-right: 0.5rem;
}

.lang-item {
  margin-bottom: 0 !important;
  padding: 0.5em 0;
}
</style>
